import './App.css';
import NavBar from './components/NavBar/NavBar';
import Blog from './pages/Blog/Blog';
import Projects from './pages/Projects/Projects';
import { useState } from 'react';
import { HashRouter, Routes, Route } from "react-router-dom";
import About from './pages/About/About';


function App() {
  const [activeMenuItem, setActiveMenuItem] = useState('Blog')
  const articleEntries = [
    { date: "Oct 10, 2024", isExternal: true, url: "https://iq.thc.org/bypassing-noexec-and-executing-arbitrary-binaries", title: "Bypassing noexec and executing arbitrary binaries", summary: "Execute arbitrary executable without touching the disk (co-authored with @skyper)" },
    { date: "May 4, 2023", isExternal: true, url: "https://0x00sec.org/t/extracting-go-embeds/34885", title: "Extracting Go Embeds", summary: "Extracting Files Embedded within Go Binaries" },
    { date: "Jan 1, 2023", isExternal: true, url: "https://0x00sec.org/t/genieacs-and-the-tale-of-default-jwt-secret/32738", title: "GenieACS And The Tale Of Default JWT Secrets", summary: "Default JWT credentials on a TR-069 ACS server and its impact" },
    { date: "Sept 2, 2022", isExternal: true, url: "https://0x00sec.org/t/bypassing-sni-filtering-feat-dpitunnel-zapret-and-geneva/31001", title: "Bypassing SNI filtering : Feat DPITunnel, Zapret and Geneva", summary: "Techniques to bypass DPI filtering mechanisms in firewalls and the like" },
    { date: "June 20, 2021", isExternal: true, url: "https://0x00sec.org/t/scanning-wans-and-stalking-strange-devices/26374", title: "Scanning WAN's And Stalking Strange Devices", summary: "Exploring the forgotten and unexplored part of modern networks...." },
  ]
  const projectEntries = [
    { name: "Ferret", desc: "Alternative to popular RDNS and Subdomain lookup services", source: "https://github.com/messede-degod/sstable-migrator", deployment_url: "https://ip.thc.org/", read_more: "https://github.com/messede-degod/sstable-migrator" },
    { name: "Bash Memexec", desc: "Execute arbitaty binaries in memory", source: "https://github.com/hackerschoice/memexec", deployment_url: "", read_more: "https://iq.thc.org/bypassing-noexec-and-executing-arbitrary-binaries" },    
    { name: "Gembe", desc: "Tool to extract Go embeds", source: "https://github.com/messede-degod/Gembe", deployment_url: "", read_more: "https://0x00sec.org/t/extracting-go-embeds/34885" },
    { name: "Segfault UI", desc: "Free root shells for everyone, powered by segfault.net", source: "https://github.com/messede-degod/SF-UI", deployment_url: "https://shell.segfault.net", read_more: "https://github.com/messede-degod/SF-UI#readme" },
    // { name: "Hive - Honeypot Orchestrator", desc: "Create and manage simple customizable honeypots", source: "", deployment_url: "", read_more: "" },
  ]
  const user = {
    name: "Messede",
    title: "Web Dev  &  Security Enthusiast",
    technologies: ["react", "angular", "golang", "python"],
    work: [
      { designation: "Moderator", place: "0x00sec", site:"https://0x00sec.org" },
      { designation: "Member", place: "Segfault", site:"https://thc.org/segfault"},
    ]
  }

  return (
    <div className="App">
      <HashRouter>
        <NavBar
          activeMenuItem={activeMenuItem}
          onSelectMenu={setActiveMenuItem}
        />
        <Routes>
          <Route exact path="/" element={<Blog articleEntries={articleEntries} />} />
          <Route exact path="/projects" element={<Projects projectEntries={projectEntries} />} />
          <Route exact path="/about" element={<About user={user} />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
